import PortfolioGrid from "components/PortfolioGrid";
import SEO from "components/SEO";
import { Link } from "gatsby";
import Layout from "layout";
import React from "react";
import {
  BasePageContext,
  TemplateHeadProps,
  TemplatePageProps,
} from "utils/types";
import { YAMLError } from "yaml/util";
const htHomeImg = require("images/hillary-taylor-home.jpg").default;

export default (props: TemplatePageProps<BasePageContext>) => {
  const { appData } = props.pageContext;

  console.log(appData.pressReleases)

  const sortedData = appData.pressReleases.slice().sort((prev, value) => {
            var getSeason = (seasonStr: String) => {
              var season = 0
              season += seasonStr === "Spring" ? .1 : 0
              season += seasonStr === "Summer" ? .2 : 0
              season += seasonStr === "Fall" ? .3 : 0
              season += seasonStr === "Winter" ? .4 : 0
              return season
            }
            var season = getSeason(value.season) 
            var prevSeason = getSeason(prev.season)
            return (value.year + season) - (prev.year + prevSeason)
          })




  return (
    <Layout appData={appData}>
      <div className="spaced-50">
        <h1 className="with-divider marg-btm-0">Press</h1>
      </div>
      <div className="bg-offwhite spaced-50">
        <div className="container--medium press-container">
          {sortedData.map((release, index) => (
            <div key={index} className="press-card">
              <h1>{release.publicationName}</h1>
              <h4>{release.season + " " + release.year}</h4>
              <a href={release.issue} target="_blank" rel="noopener noreferrer">
                <img src={release.cover} />
              </a>
              <a
                href={release.issue}
                target="_blank"
                rel="noopener noreferrer"
                className="article"
              >
                Read Article
              </a>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const Head = (props: TemplateHeadProps<BasePageContext>) => {
  const { pressReleases } = props.pageContext.appData;

  return (
    <SEO
      title="Press"
      ogMeta={{
        image: pressReleases[0]?.cover ?? htHomeImg,
      }}
    ></SEO>
  );
};
